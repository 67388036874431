.upload-button{
    padding: 0.5rem 1rem;
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 0 1rem;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px !important;
    border: none;
    outline: none;
    color: #fff;
    border: 1px solid #FF4820;
}

body {
    overflow-x: hidden;
}

.user-input-output{
    padding: 0.5rem 1rem;
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 0 1rem;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px !important;
    border: none;
    outline: none;
    color: #fff;
}

.clipboard_code-wrap {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    width: 100%;
    display: flex;
}

.clipboard_code {
    z-index: 5;
    background-color: var(--dark-slate-grey);
    color: var(--white-smoke);
    border-radius: 1rem;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 34.375rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.25rem;
    font-family: Roboto Mono, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    display: flex;
    position: relative;
}

.downloadButton{
    padding: 0.5rem 1rem;
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding: 0 1rem;
    cursor: pointer;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color: #fff;
    border: 1px solid #FF4820;
    background-color: #FF4820;
    margin: 4px;
    border-radius: 5px;

}
.upload-button:hover{
    border: 2px solid #FF4820;
    color: #fff;
}

.gpt3__header {
    display: flex;
    flex-direction: row;

    justify-content: center;
}

.gpt3__header p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 150%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}



.gpt3__header-content__input textarea {
    flex: 2;
    width: 100%;
    min-width: 220px;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-confirmationMessage h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #00ff6a;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

#discord-button {
	
	.icon {
		width: 25px;
		height: 25px;
		margin-right: 15px;
		
		svg {
			fill: white;
		}
	}
	
	a {
		color: white;
		font-weight: bold;
		border-radius: 8px;
		display: inline-flex;
		align-items: center;
		padding: 10px 15px;
		background-color: #7289da;
		text-decoration: none;
		
		&:hover {
			background-color: #6a7fc9;
		}
	}
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
    
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button,
    .gpt3__header-content__input textarea {
        font-size: 16px;
        line-height: 24px;
    }

}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 10px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button,
    .gpt3__header-content__input textarea {
        font-size: 12px;
        line-height: 16px;
    }


}

.normal-code{
    display:inline-block;
 }
 
 .small-code{
    display:none;
 }

 @media screen and (max-width: 1050px) {
    .normal-code{
       display:none;
    }

    .small-code{
       display:inline-block;
       font-size: 10px;
    }
}